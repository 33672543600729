import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import MaleUser from "../../../assets/MaleUser.svg";
import FemaleUser from "../../../assets/FemaleUser.svg";
import BusinessUser from "../../../assets/BusinessUser.svg";
import EditImg from "../../../assets/EditImg.svg";
import UserIcon from "../../../assets/icon-user.svg";
import PasswordIcon from "../../../assets/icon-unlock.svg";
import VerificationIcon from "../../../assets/icon-onboarding.svg";
import IconQuestion from "../../../assets/IconQuestion.svg";
import PromoCode from "../../../assets/icon-promocode.svg";
import MyBonuses from "../../../assets/icon-bonus.svg";
import LanguageIcon from "../../../assets/icon-globe.svg";
import WithdrawalIcon from "../../../assets/icon-arrow-up-circle.svg";
import DepositIcon from "../../../assets/icon-arrow-down-circle.svg";
import HistoryIcon from "../../../assets/icon-clock.svg";
import TextDocIcon from "../../../assets/icon-file.svg";
import IconCamera from "../../../assets/IconCamera.png";
import { UserStatus } from "../components/account-status";
import { AccountOptionCard } from "../components/account-option-card";
import { EditProfilePhoto } from "../components/edit-profile-photo";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { LanguageModal } from "../components/language-modal";
import { useStore } from "zustand";
import { blobToFile, getAvatarUrl, showNotification } from "helpers/functions";
import {
  accountStore,
  KYC_Status,
  KYC_Step,
  useUserKYCStatus,
} from "entities/account";
import { useTranslation } from "react-i18next";
import TakePhotoModal from "pages/onboarding/components/take-photo-modal";
import { DocumentType, EntityType } from "static/types";
import { useBlockScrollbar } from "hooks/useBlockScrollbar";
import {
  sessionStore,
  UserVerifyStatus,
  useUserName,
} from "entities/session/model/sessionModel";
import { PromoCodeModal } from "../components/promo-code-modal";
import Modal from "widgets/auth/modal";

export const AccountPage = () => {
  const [editImgModal, setEditImgModal] = useState(false);
  const [languageModal, setLanguageModal] = useState(false);
  const [promoCodeModal, setPromoCodeModal] = useState(false);
  const [video, setVideo] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const {
    user,
    entity,
    updateUserAvatar,
    setInactiveEmailModal,
    setInactivePinCodeModal,
  } = useStore(sessionStore);
  const username = useUserName();
  const { t: err } = useTranslation("errors");
  const { t } = useTranslation("app");
  const { uploadAvatar, removeAvatar, kyc, kyb, questionnaireStatus } =
    useStore(accountStore);
  const navigate = useNavigate();
  const kycbStatus = useUserKYCStatus();
  const location = useLocation();

  const [verificationRequestModal, setVerificationRequestModal] = useState(
    location.state?.welcomeModal || false
  );

  const [questionsRequestModal, setQuestionsRequestModal] = useState(
    location.state?.welcomeModal || false
  );

  const openFileSelector = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      e.target.value = "";
      uploadAvatar(file, err, updateUserAvatar);
    }
  };
  const closeVideo = () => {
    setVideo(false);

    if (videoRef.current) {
      const mediaStream = videoRef.current.srcObject as MediaStream;
      if (mediaStream) {
        const tracks = mediaStream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }

      videoRef.current.srcObject = null;
    }
  };

  useBlockScrollbar(video || editImgModal || languageModal || promoCodeModal, [
    video,
    editImgModal,
    languageModal,
    promoCodeModal,
  ]);

  const onUploadAvatar = (image: any) => {
    uploadAvatar(blobToFile(image, `AVATAR.jpg`), err, updateUserAvatar);
  };

  const getUserDefaultImage = () => {
    if (kyc || kyb) {
      if (entity === EntityType.Individual) {
        if (kyc && kyc.gender === "female") {
          return FemaleUser;
        }
      } else {
        return BusinessUser;
      }
    }

    return MaleUser;
  };

  const accountOptions = [
    {
      onClick: () => navigate(PATH_LIST.personal),
      image: UserIcon,
      option: t("personalInfo"),
      notification: !user?.isEmailVerified,
    },
    ...(kycbStatus != KYC_Status.Success
      ? [
          {
            onClick: () => navigateVerification(),
            image: VerificationIcon,
            option: t("verification"),
          },
        ]
      : []),
    ...(kycbStatus == KYC_Status.Success &&
    questionnaireStatus !== null &&
    questionnaireStatus !== KYC_Status.Success
      ? [
          {
            onClick: () => navigate(PATH_LIST.questions),
            image: IconQuestion,
            option: t("questionnaire"),
          },
        ]
      : []),
    ...(kycbStatus == KYC_Status.Success
      ? [
          {
            onClick: () => {
              if (!user?.hasPinCode) {
                setInactivePinCodeModal(true);
              } else if (!user.isEmailVerified) {
                setInactiveEmailModal(true);
              } else {
                navigate(PATH_LIST.signUserContract);
              }
            },
            image: TextDocIcon,
            option: t("contract"),
          },
        ]
      : []),
    {
      onClick: () => setPromoCodeModal(true),
      image: PromoCode,
      option: t("promoCode"),
    },
    {
      onClick: () => navigate(PATH_LIST.myBonuses),
      image: MyBonuses,
      option: t("bonus"),
    },
    {
      onClick: () => navigate(PATH_LIST.security),
      image: PasswordIcon,
      option: t("security"),
      notification: !user?.hasPinCode,
    },
    {
      onClick: () => navigate(PATH_LIST.tariffs),
      image: TextDocIcon,
      option: t("myTariffs"),
    },
    {
      onClick: () => setLanguageModal(true),
      image: LanguageIcon,
      option: t("language"),
    },
  ];
  const depositWithdrawalOptions = [
    {
      onClick: () => navigate(PATH_LIST.deposit),
      image: DepositIcon,
      option: t("deposit"),
    },
    {
      onClick: () => navigate(PATH_LIST.withdrawal),
      image: WithdrawalIcon,
      option: t("withdrawal"),
    },
  ];
  const reportOptions = [
    {
      onClick: () => navigate(PATH_LIST.history),
      image: HistoryIcon,
      option: t("history"),
    },
    {
      onClick: () => navigate(PATH_LIST.brokerageReport),
      image: TextDocIcon,
      option: t("brokerageReports"),
    },
    // navigate(PATH_LIST.taxReport);
  ];

  const navigateVerification = () => {
    if (entity == EntityType.Individual) {
      if (kyc) {
        if (kyc.status === KYC_Status.Pending) {
          setVerificationRequestModal(true);
        } else {
          const steps = {
            [KYC_Step.Gender]: PATH_LIST.registerGender,
            [KYC_Step.Address]: PATH_LIST.personalAddress,
            [KYC_Step.ProofOfAddress]: PATH_LIST.proofOfAddressAddressPhotoPage,
            [KYC_Step.Birthday]: PATH_LIST.registerBirthday,
            [KYC_Step.Passport]: PATH_LIST.chooseDocType,
            [KYC_Step.Residence]: PATH_LIST.registerCitizenship,
            [KYC_Step.UtilityBill]: PATH_LIST.utilityBillPhotoPage,
            [KYC_Step.Selfie]: PATH_LIST.selfieWithDocumentPage,
          };
          // @ts-ignore
          if (steps[kyc.step]) {
            // @ts-ignore
            navigate(steps[kyc.step]);
          } else {
          }
        }
      } else {
        navigate(PATH_LIST.citizenship);
      }
    } else {
      if (kyb) {
        if (kyb.status === KYC_Status.Pending) {
          setVerificationRequestModal(true);
        } else {
          navigate(PATH_LIST.signBusinessContract);
        }
      } else {
        navigate(PATH_LIST.citizenship);
      }
    }
  };

  return (
    <div className="account">
      <div className="user-info">
        <div onClick={() => setEditImgModal(true)} className="user-image">
          {user?.avatar ? (
            <img
              className="profile-img profile-img-active"
              src={getAvatarUrl(user.avatar)}
              alt="User"
            />
          ) : (
            <img
              className="profile-img"
              src={getUserDefaultImage()}
              alt="User icon"
            />
          )}
          <img className="editImg" src={EditImg} alt="Edit Image" />
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </div>
        <p>{username}</p>
        <UserStatus status={user?.tariff} />
      </div>

      {!kycbStatus && (
        <p className="acc-verification-status">{t("yourAccIsNotVerified")}</p>
      )}
      {kycbStatus == KYC_Status.Pending && (
        <p className="acc-verification-status">
          {t("verificatioRequestReviewed")}
        </p>
      )}
      {kycbStatus == KYC_Status.Success && !user?.signContract && (
        <p className="acc-verification-status">{t("signToMakeTransactions")}</p>
      )}

      <div className="account-options">
        <p className="part-title">{t("account")}</p>
        {accountOptions.map((item, index) => (
          <AccountOptionCard
            key={index}
            onClick={item.onClick}
            image={item.image}
            option={item.option}
            notification={item.notification}
          />
        ))}

        {kycbStatus == KYC_Status.Success && (
          <>
            <p className="part-title">{t("deposit&Withdrawal")}</p>
            {depositWithdrawalOptions.map((item, index) => (
              <AccountOptionCard
                key={index}
                onClick={item.onClick}
                image={item.image}
                option={item.option}
              />
            ))}
          </>
        )}

        {kycbStatus == KYC_Status.Success && (
          <>
            <p className="part-title">{t("reports")}</p>
            {reportOptions.map((item, index) => (
              <AccountOptionCard
                key={index}
                onClick={item.onClick}
                image={item.image}
                option={item.option}
              />
            ))}
          </>
        )}
      </div>

      {editImgModal === true && (
        <EditProfilePhoto
          onGalleryClick={() => {
            openFileSelector();
            setEditImgModal(false);
          }}
          onCameraClick={() => {
            setVideo(true);
            setEditImgModal(false);
          }}
          onRemoveClick={() => {
            removeAvatar(err, updateUserAvatar);
            setEditImgModal(false);
          }}
          remove={!!user?.avatar}
          onClose={() => setEditImgModal(false)}
        />
      )}
      {languageModal === true && (
        <LanguageModal
          onClose={() => {
            setTimeout(() => {
              setLanguageModal(false);
            }, 200);
          }}
        />
      )}
      {promoCodeModal === true && (
        <PromoCodeModal
          onClose={() => {
            setTimeout(() => {
              setPromoCodeModal(false);
            }, 200);
          }}
        />
      )}
      {video === true && (
        <TakePhotoModal
          className="acc-take-photo"
          onOpen={(image: string) => {
            onUploadAvatar(image);
            closeVideo();
          }}
          onClose={closeVideo}
          type={DocumentType.Avatar}
        ></TakePhotoModal>
      )}

      {verificationRequestModal && (
        <Modal
          customModal="modalLogOut verificationRequestModal"
          onClick={() => setVerificationRequestModal(false)}
          onHide={() => setVerificationRequestModal(false)}
          title={t("verificationRequest")}
          text={t("verificationRequestSent")}
          showSecondBtn={false}
          customOutlet="customOutlet"
        />
      )}

      {questionsRequestModal && (
        <Modal
          customModal="modalLogOut verificationRequestModal"
          onClick={() => setQuestionsRequestModal(false)}
          onHide={() => setQuestionsRequestModal(false)}
          title={t("questionsModalTitle")}
          text={t("questionsModalText")}
          showSecondBtn={false}
          customOutlet="customOutlet"
        />
      )}
    </div>
  );
};
