import React, { FC, useState } from "react";
import Button from "shared/ui/button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "shared/ui/form-field";
import { PasswordField } from "../components";
import { LoginUserDto, ResponseCodes, sircapApi } from "shared/api/sircap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATH_LIST, RegisterStepPages } from "shared/lib/react-router";
import { toast } from "react-toastify";
import { useStore } from "zustand";
import { RegisterStep, onboardModel } from "entities/onboard";
import { MapProfileInfoFromUser } from "helpers/mappings";
import {
  RegisterEnum,
  defaultEntityDocs,
} from "entities/onboard/model/onboardModel";
import TermsCheckbox from "pages/onboarding/components/terms-checkbox";
import { useSchema } from "helpers/functions";
import { sessionModel } from "entities/session";
import { accountStore } from "entities/account";

const validateEmail = (email: string | undefined) => {
  return Yup.string().email().isValidSync(email);
};

const validatePhone = (phone: string | undefined) => {
  return Yup.string()
    .test((phone) => {
      return phone &&
        phone.toString().length >= 8 &&
        phone.toString().length <= 14
        ? true
        : false;
    })
    .isValidSync(phone);
};

const LoginSchema = (t: any) =>
  Yup.object().shape({
    email_or_phone: Yup.string()
      .required(t("emailPhoneIsRequired"))
      .test("email_or_phone", "Email / Phone is invalid", (value) => {
        return validateEmail(value) || validatePhone(value);
      }),
    password: Yup.string().required(t("passwordIsRequired")),
  });

interface ILoginSchema {
  email_or_phone: string;
  password: string;
}
const initialValues: ILoginSchema = {
  email_or_phone: "",
  password: "",
};
type PropsType = {};
export const PasswordLoginForm: FC<PropsType> = ({}) => {
  const navigate = useNavigate();
  const { t: err } = useTranslation("errors");
  const { t } = useTranslation("onboarding-auth");

  const { syncUser, clear } = useStore(onboardModel.onboardStore);
  const { addUser } = useStore(sessionModel.sessionStore);
  const { synckyc, synckyb } = useStore(accountStore);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEntity, setIsEntity] = useState(false);
  const schema = useSchema(LoginSchema);

  const onSubmit = async (values: ILoginSchema) => {
    setIsSubmit(true);
    const { email_or_phone, password } = values;

    const form: LoginUserDto = {
      email: validateEmail(email_or_phone) ? email_or_phone : "",
      phone: validatePhone(email_or_phone)
        ? email_or_phone.replace(/\D/g, "")
        : "",
      password: password,
      entity: isEntity ? RegisterEnum.Business : RegisterEnum.Individual,
    };

    const resp = await sircapApi.auth.login(form);
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.UserIsNotExists:
        case ResponseCodes.WrongEmailOrPassword:
          toast.error(err(String(resp.error.code)));
          break;

        default:
          break;
      }
    } else {
      clear();
      const {
        user,
        accessToken,
        refreshToken,
        entity,
        kyc,
        kyb,
        questionnaireStatus,
      } = resp.data.data;
      syncUser(user, entity);
      synckyc(kyc || null);
      synckyb(kyb || null);
      accountStore.setState({ questionnaireStatus });
      addUser(user, accessToken, refreshToken, entity);

      if (user.status !== 1) {
        navigate(PATH_LIST.registration);
      } else {
        toast.success("Success login");
        navigate(PATH_LIST.home);
      }
    }
    setIsSubmit(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        onSubmit({
          email_or_phone: values.email_or_phone.trim(),
          password: values.password.trim(),
        });
      }}
    >
      {(formik) => {
        const { errors, touched, isValid, dirty } = formik;
        return (
          <Form>
            <div className="password-login-choose-acc-type">
              <button
                className={!isEntity ? "active-type-acc" : ""}
                onClick={(e) => {
                  setIsEntity(false);
                  e.preventDefault();
                }}
              >
                {t("individual")}
              </button>
              <button
                className={isEntity ? "active-type-acc" : ""}
                onClick={(e) => {
                  setIsEntity(true);
                  e.preventDefault();
                }}
              >
                {t("business")}
              </button>
            </div>

            <FormField
              type="text"
              id="email_or_phone"
              label={t("emailAddressOrPhoneNumber")}
              isInvalid={!!(errors.email_or_phone && touched.email_or_phone)}
              placeholder={t("enterYourEmailOrPhoneNumber")}
              autoFocus={true}
            />

            <PasswordField
              id="password"
              label={t("password")}
              isInvalid={!!(errors.password && touched.password)}
              placeholder={t("enterYourPassword")}
              autoFocus={false}
            />

            {/* <TermsCheckbox
              isChecked={isEntity}
              className={"legalEntityCheckbox"}
              term={t("imLegalEntity")}
              htmlForId="entity-type"
              onChange={() => setIsEntity(!isEntity)}
            /> */}

            <Button
              type="submit"
              disabled={!isValid || isSubmit}
              variant="dark"
            >
              {t("logIn")}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
