import React, { FC } from "react";
import "./style.scss";
import AccOptionIcon from "../../../../assets/AccOptionIcon.svg";

type PropType = {
  image: any;
  option: string;
  onClick?: () => void;
  notification?: boolean;
};

export const AccountOptionCard: FC<PropType> = ({
  image,
  option,
  onClick,
  notification,
}) => {
  return (
    <div onClick={onClick} className="account-option-card">
      <div>
        <img src={image} alt="option-icon" />
        <p className={notification ? "warning-icon" : ""}>{option}</p>
      </div>
      <img src={AccOptionIcon} alt="icon-right" />
    </div>
  );
};
