import React, { FC } from "react";
import "./style.scss";

type PropType = {
  img: any;
  name: string;
  price: number;
  hidden?: boolean;
  onClick?: () => void;
};

export const AccountItem: FC<PropType> = ({
  img,
  name,
  price,
  hidden,
  onClick,
}) => {
  return (
    <div onClick={onClick} className="account-card">
      <div>
        <img src={img} alt="flag" />
        <span>{name}</span>
      </div>
      <p className={hidden ? "hidden-price" : ""}>{price}</p>
    </div>
  );
};
