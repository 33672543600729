import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import "./style.scss";
import OpenCameraButton from "widgets/open-camera-btn";
import TakePhotoModal from "../components/take-photo-modal";
import SubmitPhotoModal from "../components/submit-photo-modal";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { onboardModel } from "entities/onboard";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { ContentType } from "shared/api/http-client";
import { blobToFile } from "helpers/functions";
import { DocumentType } from "static/types";
import VerificationStepsHeader from "../components/verification-steps-header";
import { accountStore } from "entities/account";

enum ModalStep {
  Zero,
  Photo,
  Submit,
}

export const ProofOfAddressPage = () => {
  const [step, setStep] = useState<ModalStep>(ModalStep.Zero);
  const [image, setImage] = useState<any>();
  const { synckyc } = useStore(accountStore);
  const { syncUser } = useStore(onboardModel.onboardStore);
  const [isSubmit, setIsSubmit] = useState(false);
  const { t: err } = useTranslation("errors");
  const { t: a } = useTranslation("app");
  const { t } = useTranslation("onboarding-auth");

  const navigate = useNavigate();

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.chooseDocType);

    return () => setLink("");
  }, []);

  const onOpen = () => {
    setStep(ModalStep.Photo);
  };

  const SubmitPhoto = async () => {
    setIsSubmit(true);
    const resp = await sircapApi.onboarding.document(
      {
        idDocType: "RESIDENCE_PERMIT",
        fiz: blobToFile(image, `RESIDENCE_PERMIT.jpg`),
      },
      { type: ContentType.FormData }
    );
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.ServerError:
          toast.error(err(String(resp.error.code)));
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should login for sync data");
          navigate(PATH_LIST.loginPassword);
          break;

        default:
          break;
      }
    } else {
      const kyc = resp.data.data;
      synckyc(kyc);

      navigate(PATH_LIST.personalAddress);
    }
    setIsSubmit(false);
  };

  return (
    <>
      <div className="proof-of-address">
        <VerificationStepsHeader percent={87.5} step={7} />

        <div className="proof-of-address-main">
          <p className="register-title">{t("documentsConfirmingAddress")}</p>
          <span className="register-subtitle">
            {t("addressDocumentsExamples")}
          </span>
          <div className="btn-wrapper">
            <OpenCameraButton customClass="cameraBtnCustom" onOpen={onOpen} />
          </div>
        </div>
      </div>

      {step === ModalStep.Photo && (
        <TakePhotoModal
          type={DocumentType.ProofAddress}
          onOpen={(image: any) => {
            setImage(image);
            setStep(ModalStep.Submit);
          }}
          onClose={() => {
            setStep(ModalStep.Zero);
          }}
        />
      )}

      {step === ModalStep.Submit && (
        <SubmitPhotoModal
          type={DocumentType.ProofAddress}
          onSubmit={SubmitPhoto}
          image={image}
          onClose={() => setStep(ModalStep.Photo)}
          isSubmit={isSubmit}
        />
      )}
    </>
  );
};
