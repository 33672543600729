import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import Icon from "../../../assets/TableTHIcon.png";
import RotateScreen from "../../../assets/RotateScreen.png";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { PortfolioRow } from "../components/portfolio-row";
import { PersonalInfoEditCard } from "../components/personal-info-edit-card";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";

export const PersonalInfoPage = () => {
  const { user } = useStore(sessionModel.sessionStore);
  const { t } = useTranslation("app");
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(PATH_LIST.account);
  };

  const changePhone = () => {
    navigate(PATH_LIST.changePhone);
  };

  return (
    <div className="personal-info">
      <div className="personal-info-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("personalInfo")}</p>
      </div>
      <div className="personal-info-main">
        <PersonalInfoEditCard
          onEdit={() => {
            navigate(PATH_LIST.changeEmail);
          }}
          name={t("email")}
          info={user?.email || ""}
          notification={!user?.isEmailVerified}
          security={!user?.isEmailVerified}
        />
        <PersonalInfoEditCard
          onEdit={() => changePhone()}
          name={t("phone")}
          info={`+${user?.phone || ""}`}
        />
      </div>
    </div>
  );
};
