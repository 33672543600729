import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useTranslation } from "react-i18next";
import Help from "../../../assets/HelpCircle.svg";
import BonusCard from "../components/bonus-card";
import { AdviserModal } from "../components/adviser-modal";
import { useStore } from "zustand";
import { accountStore } from "entities/account";
import NotFound from "shared/ui/NotFound";
import Loading from "widgets/loading";

export const MyBonusesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("app");
  const { t: err } = useTranslation("errors");
  const [activeTab, setActiveTab] = useState("Active");
  const [modal, setModal] = useState("");
  const { isLoading, bonusInfo, getBonusInfo, activateBonus } =
    useStore(accountStore);

  useEffect(() => {
    getBonusInfo();
  }, []);

  const onCheck = (id: number) => {
    activateBonus(id, err);
  };

  const activeBonuses = bonusInfo?.tasks.filter(
    (i) => !i.activated && !i.expired
  );
  const completedBonuses = bonusInfo?.tasks.filter(
    (i) => i.activated || i.expired
  );

  if (isLoading || !bonusInfo) {
    return <Loading />;
  }

  return (
    <div className="my-bonuses">
      <div className="my-bonuses-header">
        <img
          className="go-back-my-bonuses"
          onClick={() => navigate(PATH_LIST.account)}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("bonus")}</p>
      </div>

      <div className="bonus-points-header">
        <p>
          {t("myBonusPoints")}
          <img onClick={() => setModal("part1")} src={Help} alt="Help" />
        </p>
        <div>
          <h1>{bonusInfo.balance}</h1>
        </div>
      </div>

      <div className="query-wrapper">
        <div className="query-type">
          <button
            className={activeTab == "Active" ? "active-btn" : ""}
            onClick={() => {
              setActiveTab("Active");
            }}
          >
            {t("active")}
          </button>

          <button
            className={activeTab == "Completed" ? "active-btn" : ""}
            onClick={() => {
              setActiveTab("Completed");
            }}
          >
            {t("completed")}
          </button>
        </div>
      </div>

      {activeTab == "Active" ? (
        <div className="my-bonuses-main">
          {activeBonuses?.length == 0 ? (
            <NotFound />
          ) : (
            <>
              {activeBonuses?.map((q) => (
                <BonusCard
                  bonusType={q.type}
                  title={q.title}
                  description={q.description}
                  timer={q.timer_hours}
                  startTimer={q.startTimer}
                  onPress={() => onCheck(q.id)}
                  expired={q.expired}
                  activated={q.activated}
                  points={q.points}
                  automatically={q.automatically}
                  manually={q.manually}
                />
              ))}
            </>
          )}
        </div>
      ) : (
        <div className="my-bonuses-main empty-bonuses-tab-main">
          {completedBonuses?.length == 0 ? (
            <>
              <p>{t("notCompleteTask")}</p>
              <span>{t("finishTasks")}</span>
            </>
          ) : activeTab === "Completed" ? (
            <>
              {completedBonuses?.map((q) => (
                <BonusCard
                  bonusType={q.type}
                  title={q.title}
                  description={q.description}
                  timer={q.timer_hours}
                  startTimer={q.startTimer}
                  onPress={() => onCheck(q.id)}
                  activated={q.activated}
                  expired={q.expired}
                  points={q.points}
                  automatically={q.automatically}
                  manually={q.manually}
                />
              ))}
            </>
          ) : (
            <NotFound />
          )}
        </div>
      )}

      {modal !== "" && (
        <AdviserModal
          title={t("earnBonusPoints")}
          text={
            t("earnBonusPointsInfo")
          }
          btnText={t("gotIt")}
          onClick={() => setModal("")}
          onHide={() => setModal("")}
        />
      )}
    </div>
  );
};
