import { sessionModel } from "entities/session";
import { sircapApi } from "shared/api/sircap";
import { Socket, io } from "socket.io-client";
import { createStore } from "zustand";

export enum ChatType {
  Support = "support",
  GPT = "gpt",
}

export enum ToChatType {
  Admin = "admin",
  User = "user",
  GPT = "gpt",
}

const baseUrl = process.env.REACT_APP_SIRCAP_API as string;

interface IOStore {
  socket: Socket;
  connected: boolean;
  isLoading: boolean;
  messages: any[];
  messagesCount: number;
  rooms: {
    id: number;
    user_id: number;
    user_type: string;
    unread_messages_user: number;
    type: string;
  }[];
  connect: () => void;
  disconnect: () => void;
  setConnected: (is: boolean) => void;
  setRooms: (list: any[]) => void;
  setMessages: (list: any[], count: number) => void;
  addNewMessage: (msg: any) => void;
  deleteMessage: (msg_id: string) => void;
  addOldMessages: (list: any[]) => void;
}
const chatStore = createStore<IOStore>()((set, get) => ({
  socket: io({ autoConnect: false }),
  connected: false,
  isLoading: true,
  messages: [],
  messagesCount: 0,
  rooms: [],

  connect: () => {
    const oldSocket = get().socket;
    if (oldSocket) oldSocket.disconnect();
    const socket = io(baseUrl, {
      path: "/chat-socket",
      autoConnect: false,
      transports: ["websocket"],
      query: {
        token: sessionModel.sessionStore.getState().accessToken,
      },
      reconnection: true,
      reconnectionDelay: 1500,
      reconnectionDelayMax: 2000,
    });

    socket
      .on("connect", () => {
        set({ connected: true, isLoading: false });
      })
      .on("disconnect", () => {
        set({ connected: false });
      })
      .on("connect_error", () => {
        set({ connected: false });
      });

    set({
      isLoading: true,
      socket: socket,
    });
    socket.connect();
  },
  disconnect: () => {
    const oldSocket = get().socket;
    if (oldSocket) oldSocket.disconnect();
  },
  setConnected: (is: boolean) => {
    set({ connected: is });
  },
  setRooms: (list: any[]) => {
    set({ rooms: list });
  },
  setMessages: (list: any[], count: number) => {
    set({ messages: list, messagesCount: count });
  },
  addNewMessage: (msg: any) => {
    set({
      messages: [...get().messages, msg],
      messagesCount: get().messagesCount + 1,
    });
  },
  deleteMessage: (msg_id: string) => {
    set({
      messages: get().messages.filter((m) => m.id !== +msg_id),
      messagesCount: get().messagesCount - 1,
    });
  },
  addOldMessages: (list: any[]) => {
    set({ messages: [...list, ...get().messages] });
  },
}));

export { chatStore };
