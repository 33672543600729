import React, { useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router"; 
import { PhoneInput } from "react-international-phone";
import Button from "shared/ui/button";
import { PhoneNumberUtil } from "google-libphonenumber";
import { useStore } from "zustand";
import { onboardStore } from "entities/onboard/model/onboardModel";
import { accountStore } from "entities/account";
import { useTranslation } from "react-i18next";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const ChangePhonePage = () => {
  const [phone, setPhone] = useState<string>("");
  const isValid: boolean = isPhoneValid(phone);
  const { isLoading, requestChangePhone } = useStore(accountStore);
  const { i18n, t: err } = useTranslation("errors");
  const { t: y } = useTranslation("yup");
  const { t } = useTranslation("app");

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(PATH_LIST.personal);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    const clearPhone = phone.replace(/\D/g, "");

    requestChangePhone(
      { phone: clearPhone, language: i18n.language },
      err,
      () => {
        navigate(PATH_LIST.verifyChangedPhone);
      }
    );
  };

  return (
    <div className="change-phone">
      <div className="change-phone-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
      </div>
      <div className="change-phone-main">
        <p>{t("yourPhone")}</p>
        <span>{t("weWillSendVerification")}</span>

        <form className="phoneForm form-change-phone" onSubmit={onSubmit}>
          <label>{t("phoneNumber")}</label>

          <PhoneInput
            defaultCountry={"am"}
            value={phone}
            onChange={(v: string) => setPhone(v)}
            inputProps={{ autoFocus: true, value: phone }}
          />

          {!isValid && <p className="validation">{y("phoneNotValid")}</p>}
          <div className="btn-wrapper">
            <Button
              className="phoneNumberBtn"
              children={t("continue")}
              variant="dark"
              disabled={!isValid || isLoading}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
