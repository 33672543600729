import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import BonusesTimer from "../../../../assets/BonusesTimer.svg";
import { getMLValue, MultiLangField } from "helpers/functions";
import moment from "moment";
import Loading from "widgets/loading";
import { useTranslation } from "react-i18next";

type PropsType = {
  bonusType: string;
  title: MultiLangField;
  description: MultiLangField;
  timer: number | null;
  startTimer: string | null;
  onPress: () => void;
  activated: boolean;
  expired: boolean;
  automatically: boolean;
  manually: boolean;
  points: number;
};

const BonusCard: FC<PropsType> = ({
  bonusType,
  title,
  description,
  timer,
  startTimer,
  onPress,
  activated,
  expired,
  automatically,
  manually,
  points,
}) => {
  const { t } = useTranslation("app");
  const [remainingTime, setRemainingTime] = useState<string>("");

  useEffect(() => {
    if (!startTimer || !timer || expired || activated) return;
    const startTime = +moment(startTimer).toDate();
    const endTime = startTime + timer * 60 * 60 * 1000;

    const updateRemainingTime = () => {
      const currentTime = new Date().getTime();
      //   const endTime = +moment(startTimer).add(timer, 'hours').toDate();
      const timeLeft = endTime - currentTime;

      if (timeLeft <= 0) {
        setRemainingTime("00:00:00");
        return;
      }

      const hours = Math.floor(timeLeft / (1000 * 60 * 60));
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      setRemainingTime(formattedTime);
    };

    const timerInterval = setInterval(updateRemainingTime, 1000);

    // Cleanup timer on unmount
    return () => clearInterval(timerInterval);
  }, []);

  return (
    <div className="bonus-card">
      <div
        style={{
          backgroundColor: activated
            ? "#27AE60"
            : expired
            ? "#EB5757"
            : "#73F35E",
        }}
        className="bonus-points"
      >
        <span
          style={{ color: activated ? "white" : expired ? "white" : "#2B2F38" }}
        >
          {activated
            ? "Completed"
            : expired
            ? "Expired"
            : "+" + points + " " + t("points")}
        </span>
      </div>

      <p>{getMLValue(title)}</p>
      <span>
        {getMLValue(description)}
        <br />
        {/* //! add from backend text + path */}
        {/* {!activated && !expired && <a href="">Verify my account</a>} */}
      </span>

      <div className="timer-check">
        {timer && startTimer ? (
          <span style={{ height: "27px" }}>
            {(activated || expired || remainingTime) && (
              <img src={BonusesTimer} alt="BonusesTimer" />
            )}
            {activated || expired ? "00:00:00" : remainingTime}
          </span>
        ) : (
          <div></div>
        )}
        {!automatically && !manually && !activated && (
          <button onClick={onPress}>{t("checkBonusTask")}</button>
        )}
      </div>
    </div>
  );
};

export default BonusCard;
