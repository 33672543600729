import React, { FC } from "react";
import Vector from "../../../../assets/Vector.svg";
import "./style.scss";

type PropType = {
  text: string;
  onClick: any;
  img: any;
};
const CitizenshipField: FC<PropType> = ({ text, onClick, img }) => {
  return (
    <div onClick={onClick} className="citizenshipField">
      <div>
        <img src={img} alt={`${img}`} />
        <p>{text}</p>
      </div>

      <img src={Vector} alt="Vector" />
    </div>
  );
};

export default CitizenshipField;
