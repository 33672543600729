import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { PersonalInfoEditCard } from "../components/personal-info-edit-card";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";
import CitizenshipField from "../components/citizenship-field";
import { useEffect, useState } from "react";
import Armenia from "../../../assets/Armenia.svg";
import Other from "../../../assets/Language.svg";
import VerificationStepsHeader from "pages/onboarding/components/verification-steps-header";
import { accountStore } from "entities/account";
import { toast } from "react-toastify";
import { EntityType } from "static/types";
import Loading from "widgets/loading";
import QuestionItem from "../components/question-item";
import Button from "shared/ui/button";

export const QuestionsPage = () => {
  const navigate = useNavigate();
  const [dto, setDto] = useState<Record<string, any>>({});
  const { questionnaire, loadQuestionnaire, submitQuestionnaire, isLoading } =
    useStore(accountStore);

  useEffect(() => {
    loadQuestionnaire();
  }, []);

  useEffect(() => {
    if (questionnaire) {
      setDto(questionnaire.answers);
    }
  }, [questionnaire]);

  const { t } = useTranslation("app");

  const onSubmit = () => {
    submitQuestionnaire(dto);

    navigate(PATH_LIST.account, { state: { questionsRequestModal: true } });
  };

  if (isLoading || !questionnaire) {
    return <Loading />;
  }

  return (
    <div className="questions-page">
      <div className="questions-page-header">
        <img
          className="go-back-portfolio"
          onClick={() => navigate(-1)}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("Questions")}</p>
      </div>
      <div className="questions-page-main">
        {questionnaire.questionnaire.map((q, i) => (
          <QuestionItem
            key={i}
            index={i}
            value={dto[q.alias] || (q.type === "multi-select" ? [] : "")}
            onChange={(v: any) => {
              setDto({ ...dto, [q.alias]: v });
            }}
            text={q.text}
            alias={q.alias}
            info={q.info}
            type={q.type}
            options={q.options}
          />
        ))}

        <div className="btn-wrapper">
          <Button
            disabled={questionnaire.questionnaire.some((q) =>
              q.type === "multi-select"
                ? !dto[q.alias] || !dto[q.alias]?.length
                : !dto[q.alias]
            )}
            type="submit"
            onClick={onSubmit}
            variant={"dark"}
          >
            {t("continue")}
          </Button>
        </div>
      </div>
    </div>
  );
};
