import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { getMLValue, MultiLangField } from "helpers/functions";
import QuestionCheckbox from "../questions-checkbox";
import "./style.scss";

type PropType = {
  value: any;
  index: number;
  onChange: (value: any) => void;
  text: MultiLangField;
  alias: string;
  info: MultiLangField;
  type: "select" | "multi-select" | "text";
  options: Array<{ label: MultiLangField; value: string }>;
};

const QuestionItem: FC<PropType> = ({
  text,
  alias,
  info,
  type,
  options,
  value,
  onChange,
  index,
}) => {
  const { t, i18n } = useTranslation("app");

  return (
    <div className="question-item">
      <div className="question-item__header">
        <p className="question-item__title">
          {index + 1}. {getMLValue(text, i18n.language)}
        </p>
        {info && (
          <p className="question-item__info">
            {getMLValue(info, i18n.language)}
          </p>
        )}
      </div>
      <div className="question-item__options">
        {type === "select" ? (
          options.map((o) => (
            <QuestionCheckbox
              font={14}
              key={o.value}
              term={getMLValue(o.label, i18n.language)}
              isChecked={value === o.value}
              onChange={() => onChange(o.value)}
              disabled={value === o.value}
            />
          ))
        ) : type === "multi-select" ? (
          options.map((o) => (
            <QuestionCheckbox
              key={o.value}
              font={14}
              term={getMLValue(o.label, i18n.language)}
              isChecked={value && value.includes(o.value)}
              onChange={() =>
                onChange(
                  value && value.includes(o.value)
                    ? value.filter((v: any) => v !== o.value)
                    : [...value, o.value]
                )
              }
              isSquare
            />
          ))
        ) : (
          <input
            className="question-item__input"
            value={value}
            onChange={(v) => onChange(v.target.value)}
            placeholder=""
          />
        )}
      </div>
    </div>
  );
};

export default QuestionItem;
