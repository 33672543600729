import { getFromLotsText } from "helpers/functions";
import React from "react";
import { useTranslation } from "react-i18next";
import { AssetType } from "static/types";

type PropsType = {
  assetType: any;
  inputValue: any;
  setInputValue: any;
  setIsActive: any;
  fromLots?: number;
  decimalPlaces?: number;
};
const QuantityInput: React.FC<PropsType> = ({
  assetType,
  inputValue,
  setInputValue,
  setIsActive,
  fromLots = 1,
  decimalPlaces,
}) => {
  const { t } = useTranslation("app");
  const handleInputChange = (e: any) => {
    const strValue = String(e.target.value);
    const value =
      assetType === String(AssetType.Currency) || decimalPlaces
        ? decimalPlaces
          ? +(+strValue).toFixed(decimalPlaces) || ""
          : +strValue || ""
        : parseInt(e.target.value);
    setInputValue(value as number);
  };

  return (
    <input
      id="quantity"
      type="number"
      // placeholder={"From " + getFromLotsText(fromLots)}
      placeholder={t("from1Lot")}
      autoComplete="off"
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
      onChange={handleInputChange}
      value={inputValue}
      min={0}
      step={1}
    />
  );
};

export default QuantityInput;
