import React, { useEffect, useState } from "react";
import GoBackButton from "shared/ui/back-button";
import Button from "shared/ui/button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { onboardModel, RegisterEnum } from "entities/onboard";
import { useStore } from "zustand";
import { toast } from "react-toastify";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";
import Modal from "widgets/auth/modal";
import FormField from "shared/ui/form-field";
import "./style.scss";
import { PhoneInput } from "react-international-phone";
import TermsCheckbox from "../components/terms-checkbox";
import { PasswordField } from "widgets/auth/components";
import { PhoneNumberUtil } from "google-libphonenumber";
import Checkmark from "../../../assets/Checkmark.svg";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const RegistrationPage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("onboarding-auth");
  const { t: y } = useTranslation("yup");
  const { t: a } = useTranslation("app");
  const { t: err } = useTranslation("errors");
  const sessionStore = useStore(sessionModel.sessionStore);
  const { addUser } = useStore(sessionModel.sessionStore);
  const registerType = onboardModel.useRegisterType();
  const { syncUser, setSome, name } = useStore(onboardModel.onboardStore) as {
    syncUser: (user: any, entity: any) => void;
    setSome: (data: {
      name: { firstName?: string; lastName?: string; companyName?: string };
    }) => void;
    name: { firstName?: string; lastName?: string; companyName?: string };
  };
  const { email, setEmail } = useStore(onboardModel.onboardStore);
  const { phoneNumber, setPhoneNumber, clear } = useStore(
    onboardModel.onboardStore
  );
  const id = onboardModel.useRegisterId();
  const [phone, setPhone] = useState<string>(phoneNumber || "");
  const phoneIsValid: boolean = isPhoneValid(phone);
  const [verificationCode, setVerificationCode] = useState("");
  const [errorOTP, setErrorOTP] = useState<boolean>(false);
  const [timer, setTimer] = useState(59);
  const [resend, setResend] = useState(false);
  const [getCode, setGetCode] = useState(false);

  const [accepted, setAccepted] = useState(false);
  const [subscribe, setSubscribe] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (getCode && !resend && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    } else if (timer === 0) {
      setResend(true);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [getCode, resend, timer]);

  const validationSchema = Yup.object().shape({
    firstName:
      registerType === RegisterEnum.Individual
        ? Yup.string().required(y("firstNameRequired"))
        : Yup.string(),
    lastName:
      registerType === RegisterEnum.Individual
        ? Yup.string().required(y("lastNameRequired"))
        : Yup.string(),
    companyName:
      registerType === RegisterEnum.Business
        ? Yup.string().required(y("companyNameRequired"))
        : Yup.string(),
    email: Yup.string()
      .email(y("emailIsRequired"))
      .required(y("emailIsRequired")),
    password: Yup.string()
      .min(6, a("valid6Characters"))
      .matches(/[A-Z]/, a("valid1Upper"))
      .matches(/[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/, a("valid1Symbol"))
      .matches(/\d/, a("valid1Number"))
      .required(a("passwordRequired")),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password")], a("passwordsDoNotMatch"))
      .required(a("passwordsDoNotMatch")),
  });

  const handelGetCode = async () => {
    const clearPhone = phone.replace(/\D/g, "");
    setIsSubmit(true);

    const resp = await sircapApi.onboarding.regPhone({
      phone: clearPhone,
      entity: registerType as RegisterEnum,
      language: i18n.language,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.UserIsExists:
          setShowModal(true);
          break;

        case ResponseCodes.ServerError:
          toast.error("Server Error");
          break;

        default:
          break;
      }
    } else {
      setPhoneNumber(clearPhone);
      const { id } = resp.data.data;
      onboardModel.onboardStore.getState().setId(id);

      setGetCode(true);
      setResend(false);
      setTimer(59);
    }
    setIsSubmit(false);
  };

  const handeEditNumber = () => {
    setGetCode(false);
    setResend(false);
    setTimer(59);
    setVerificationCode("");
    setPhone("");
  };

  const handleResendClick = async () => {
    setIsSubmit(true);
    const resp = await sircapApi.onboarding.regPhone({
      phone: phoneNumber,
      entity: registerType as RegisterEnum,
      language: i18n.language,
    });

    if (resp.error) {
      //
    } else {
      setResend(false);
      setTimer(59);
    }
    setIsSubmit(false);
  };

  const onPhoneRegister = async (code: number) => {
    const resp = await sircapApi.onboarding.verifyPhone({
      id: id as number,
      code,
      entity: registerType,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.WrongCode:
          setErrorOTP(true);
          break;

        default:
          break;
      }
    } else {
      setErrorOTP(false);
      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);
      setResend(true);
    }
  };

  useEffect(() => {
    if (verificationCode.length === 4) {
      onPhoneRegister(+verificationCode);
    }
  }, [verificationCode]);

  const isFilled = verificationCode.length === 4;

  const onSubmit = async (values: {
    firstName?: string;
    lastName?: string;
    companyName?: string;
    email: string;
    password: string;
    repeatPassword: string;
  }) => {
    setIsSubmit(true);

    const resp = await sircapApi.onboarding.register({
      //@ts-ignore
      companyName: values.companyName,
      //@ts-ignore
      firstName: values.firstName,
      //@ts-ignore
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      subscribe,
    });
    if (resp.error) {
      toast.error(err(String(resp.error.code)));
    } else {
      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);

      navigate(PATH_LIST.home, { state: { welcomeModal: true } });
    }
    setIsSubmit(false);
  };

  return (
    <div className="registration">
      <GoBackButton
        className="registration-go-back"
        link={PATH_LIST.chooseAccType}
      />
      <p className="page-title">{t("registration")}</p>

      <Formik
        initialValues={{
          firstName: name?.firstName || "",
          lastName: name?.lastName || "",
          companyName: name?.companyName || "",
          email: email || "",
          password: "",
          repeatPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isValid }) => (
          <Form>
            {registerType === RegisterEnum.Individual ? (
              <div className="name-lastname-wrapper">
                <FormField
                  customClass="registration-field"
                  type="text"
                  id="firstName"
                  label={t("firstName")}
                  isInvalid={!!(errors.firstName && touched.firstName)}
                  placeholder={t("enterFirstName")}
                  autoFocus={true}
                />
                <FormField
                  customClass="registration-field"
                  type="text"
                  id="lastName"
                  label={t("lastName")}
                  isInvalid={!!(errors.lastName && touched.lastName)}
                  placeholder={t("enterLastName")}
                />
              </div>
            ) : (
              <FormField
                customClass="registration-field"
                type="text"
                id="companyName"
                label={t("companyName")}
                isInvalid={!!(errors.companyName && touched.companyName)}
                placeholder={t("enterCompanyName")}
                autoFocus={true}
              />
            )}

            <FormField
              customClass="registration-field"
              type="email"
              id="email"
              label={t("email")}
              isInvalid={!!(errors.email && touched.email)}
              placeholder={t("enterYourEmail")}
            />

            {!getCode ? (
              <div
                style={{
                  paddingBottom:
                    !phoneIsValid && phone !== "+374 " ? "23px" : 0,
                }}
                className="registration-phone-input-wrapper"
              >
                <label>{t("phoneNumber")}</label>
                <PhoneInput
                  className="registration-phone-input"
                  defaultCountry={"am"}
                  value={phone}
                  onChange={(v: string) => setPhone(v)}
                  inputProps={{ autoFocus: false, value: phone }}
                />
                <button
                  type="button"
                  disabled={isSubmit || !phoneIsValid}
                  onClick={() => {
                    phoneIsValid && handelGetCode();
                  }}
                  className="phone-verify-btn"
                >
                  {t("ցetСode")}
                </button>

                {!phoneIsValid && phone !== "+374 " && (
                  <p
                    style={{ fontSize: "14px", marginTop: "4px" }}
                    className="validation"
                  >
                    {y("phoneNotValid")}
                  </p>
                )}
              </div>
            ) : (
              <div className="registration-otp-code-input-wrapper">
                <div>
                  <p>
                    {t("codeSentTo")} <span>{phone}</span>
                  </p>
                  <p
                    style={{
                      display: isFilled && !errorOTP ? "none" : "block",
                    }}
                    onClick={handeEditNumber}
                  >
                    {t("editNumber")}
                  </p>
                </div>

                <input
                  className="registration-field otp-code-input"
                  style={{
                    borderColor: isFilled ? (errorOTP ? "red" : "green") : "",
                    outlineColor: isFilled ? (errorOTP ? "red" : "green") : "",
                    color: isFilled ? (errorOTP ? "red" : "green") : "",
                  }}
                  type="text"
                  placeholder={t("enterCode")}
                  value={verificationCode}
                  onChange={(e) => {
                    setVerificationCode(e.target.value.replace(/[^0-9]+/g, ""));
                  }}
                  maxLength={4}
                  disabled={isFilled && !errorOTP}
                />

                {!resend ? (
                  <span className="resend-otp-code">
                    {t("sendCodeAgain")}... {timer}
                  </span>
                ) : (
                  <span
                    // style={{
                    //   display: isFilled && !errorOTP ? "none" : "block",
                    // }}
                    onClick={handleResendClick}
                    className="resend-otp-code"
                  >
                    {isFilled && !errorOTP ? (
                      <img src={Checkmark} alt="Checkmark" />
                    ) : (
                      t("resendCode")
                    )}
                  </span>
                )}
              </div>
            )}

            <PasswordField
              className="registration-password-field"
              id="password"
              label={t("password")}
              isInvalid={!!(errors.password && touched.password)}
              placeholder={t("enterYourPassword")}
              autoFocus={false}
            />
            <PasswordField
              className="registration-password-field"
              id="repeatPassword"
              label={a("confirmPassword")}
              isInvalid={!!(errors.repeatPassword && touched.repeatPassword)}
              placeholder={a("confirmYourPassword")}
              autoFocus={false}
            />

            <div className="registration-terms-section">
              <TermsCheckbox
                term={t("consentToProcessingData")}
                PrivacyPolicy={t("privacyPolicy")}
                htmlForId="1"
                isChecked={accepted}
                onChange={() => setAccepted(!accepted)}
              />
              <TermsCheckbox
                term={t("subscribeToNewsletter")}
                htmlForId="2"
                isChecked={subscribe}
                onChange={() => setSubscribe(!subscribe)}
              />
            </div>

            <Button
              className="registration-btn"
              type="submit"
              disabled={
                !isValid || isSubmit || !accepted || !isFilled || errorOTP
              }
              variant="dark"
            >
              {t("register")}
            </Button>
          </Form>
        )}
      </Formik>

      {showModal && (
        <Modal
          title={t("accExists")}
          text={t("accWhitPNExists")}
          btnText={t("logIn")}
          showSecondBtn={false}
          onClick={() => navigate(PATH_LIST.login)}
          onHide={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
