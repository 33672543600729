import React, { useState, useEffect } from "react";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import Button from "shared/ui/button";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import { PATH_LIST } from "shared/lib/react-router";
import { useNavigate } from "react-router-dom";
import { onboardModel } from "entities/onboard";
import {
  RegisterEnum,
  onboardStore,
} from "entities/onboard/model/onboardModel";
import { useStore } from "zustand";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import TermsCheckbox from "pages/onboarding/components/terms-checkbox";
import { sessionModel } from "entities/session";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const ForgotPasswordPage: React.FC = () => {
  const [phone, setPhone] = useState<string>("");
  const { setPhoneNumber } = useStore(onboardStore);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { i18n } = useTranslation();
  const { t } = useTranslation("onboarding-auth");
  const { t: y } = useTranslation("yup");
  const { t: err } = useTranslation("errors");

  const { setLink } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.loginPassword);

    return () => setLink("");
  }, []);

  const isValid: boolean = isPhoneValid(phone);
  const navigate = useNavigate();
  const { setRegisterType } = useStore(onboardModel.onboardStore);
  const [isEntity, setIsEntity] = useState(false);

  const onRegister = async () => {
    setIsSubmitting(true);
    const clearPhone = phone.replace(/\D/g, "");

    const resp = await sircapApi.auth.forgot({
      phone: clearPhone,
      language: i18n.language,
      entity: isEntity ? RegisterEnum.Business : RegisterEnum.Individual,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.ServerError:
          toast.error(err("500"));
          break;

        case ResponseCodes.UserIsNotExists:
          toast.error(err(String(resp.error.code)));
          break;

        default:
          toast.error(err("500"));
          break;
      }
    } else {
      setRegisterType(
        isEntity ? RegisterEnum.Business : RegisterEnum.Individual
      );
      setPhoneNumber(clearPhone);
      const { id } = resp.data.data;
      onboardModel.onboardStore.getState().setId(id);
      const status = resp.data.code;
      switch (status) {
        case ResponseCodes.Success:
          onboardModel.onboardStore.getState().setId(id);

          navigate(PATH_LIST.forgotPasswordVerify);
          break;
        case ResponseCodes.NotRegistered:
          toast.warning("Please register for continue");
          onboardModel.onboardStore.getState().setId(id);

          navigate(PATH_LIST.chooseAccType);
          break;
        case ResponseCodes.Forbidden:
          const { step } = resp.data.data;
          onboardModel.onboardStore.getState().setStep(step);

          navigate(PATH_LIST.chooseAccType);
          break;
        default:
          break;
      }
    }
    setIsSubmitting(false);
  };

  return (
    <div className="registerAccount forgetPasswordPage">
      <GoBackButton backByOne />
      <p>{t("forgotYourPassword")}</p>
      <span>{t("enterPhone")}</span>

      <div className="password-login-choose-acc-type">
        <button
          className={!isEntity ? "active-type-acc" : ""}
          onClick={(e) => {
            setIsEntity(false);
            e.preventDefault();
          }}
        >
          {t("individual")}
        </button>
        <button
          className={isEntity ? "active-type-acc" : ""}
          onClick={(e) => {
            setIsEntity(true);
            e.preventDefault();
          }}
        >
          {t("business")}
        </button>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (isValid) {
            onRegister();
          }
        }}
      >
        <label>{t("phoneNumber")}</label>

        <PhoneInput
          defaultCountry={"am"}
          value={phone}
          onChange={(v: string) => setPhone(v)}
          inputProps={{ autoFocus: true }}
        />
        {!isValid && <p className="validation">{y("phoneNotValid")}</p>}

        <Button
          className="phoneNumberBtn"
          children={t("submit")}
          variant="dark"
          disabled={!isValid || isSubmitting}
          type="submit"
        />
      </form>
    </div>
  );
};
