import React, { useCallback, useEffect, useRef, useState } from "react";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useTranslation } from "react-i18next";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { toast } from "react-toastify";
import { useStore } from "zustand";
import { onboardModel } from "entities/onboard";
import { sessionModel } from "entities/session";
import GoBack from "../../../assets/GoBackApp.svg";
import { accountStore } from "entities/account";
import { sessionStore } from "entities/session/model/sessionModel";

export const PINCodePage = () => {
  const { syncUser } = useStore(onboardModel.onboardStore);
  const { t } = useTranslation("onboarding-auth");
  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  const { t: y } = useTranslation("yup");
  const { t: err } = useTranslation("errors");
  const AuthInputRef = useRef<AuthCodeRef>(null);
  const [pin, setPin] = useState("");
  const [result, setResult] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const [step, setStep] = useState("setPIN");
  const { changePinCode } = useStore(accountStore);
  const { updateUser } = useStore(sessionStore);

  const setAndVerify = {
    setPIN: t("setPINCode"),
    verify: t("verifyPINCode"),
  };
  const navigate = useNavigate();

  const onRequest = async (newPin: any) => {
    changePinCode(
      { pinCode: "", newPinCode: newPin },
      err,
      (status: boolean) => {
        if (status) {
          toast.success("Pin code successfully changed");
          updateUser({ hasPinCode: true });
          navigate(PATH_LIST.account);
        } else {
          setPin("");
          setStep("new");
        }
      }
    );
  };

  const handleOnChange = useCallback(
    (newValue: string) => {
      setError(false);
      if (newValue.length === 4 && step == "setPIN") {
        AuthInputRef.current?.clear();
        setResult(newValue);
        setStep("verify");
      } else if (newValue.length === 4 && step == "verify") {
        setPin(newValue);
        if (result !== newValue) {
          setError(true);
        } else {
          onRequest(newValue);
        }
      }
    },
    [step, result, pin]
  );
  const isFilled = (step == "setPIN" ? result : pin).length === 4;

  return (
    <div className="pin-code-page">
      <div className="change-PIN-header">
        <img
          className="go-back-portfolio"
          onClick={() => navigate(-1)}
          src={GoBack}
          alt="Go Back"
        />
        <p>{setAndVerify[step as keyof typeof setAndVerify]}</p>
      </div>

      <div className="change-PIN-main">
        <p>{t("pinCodeInfo")}</p>
        <AuthCode
          disabled={isLoading}
          ref={AuthInputRef}
          allowedCharacters="numeric"
          length={4}
          onChange={handleOnChange}
          inputClassName={error ? "red" : isFilled ? "green" : ""}
        />
        {error && (
          <span className="error-verify error-verify-pin">
            {step == "verify" ? y("pinDontMatch") : y("incorrectPIN")}
          </span>
        )}
      </div>
    </div>
  );
};
