import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import ReplenishmentDetails from "../components/replenishment-details";
import Currency from "../components/deposit-currency";
import { CurrencyModal } from "../components/currency-modal";
import { useBlockScrollbar } from "hooks/useBlockScrollbar";
import { accountStore, useDepositInfo } from "entities/account";
import { useStore } from "zustand";
import { CURRENCY_FLAGS } from "static/currencies";
import Loading from "widgets/loading";
import {
  useUserContract,
  useUserName,
} from "entities/session/model/sessionModel";
import { useTranslation } from "react-i18next";
import Button from "shared/ui/button";
import Modal from "widgets/auth/modal";
import { toast } from "react-toastify";

export const DepositPage: FC = () => {
  const navigate = useNavigate();
  const [currencyModal, setCurrencyModal] = useState<
    null | "bank" | "currency"
  >(null);
  const { t } = useTranslation("app");
  const { t: err } = useTranslation("errors");
  const {
    isLoading,
    depositInfo: depositAccounts,
    getDepositInfo,
    selectedDepositCurrency,
    selectCurrency,
    requestDeposit,
  } = useStore(accountStore);
  const [selectedBank, setSelectedBank] = useState<number | null>(null);
  const [depositForm, setDepositForm] = useState(false);
  const [changeFromModal, setChangeFromModal] = useState(false);
  const depositInfo = useDepositInfo(selectedDepositCurrency, selectedBank);
  const currencies = new Set(depositAccounts?.map((v) => v.currency.code));
  const userName = useUserName();
  const contract = useUserContract();
  const [depositAmount, setDepositAmount] = useState<number>();

  const handleNavigate = () => {
    navigate(-1);
  };
  useEffect(() => {
    getDepositInfo();
  }, []);

  useBlockScrollbar(!!currencyModal || depositForm, [
    currencyModal,
    depositForm,
  ]);

  const handleBankChange = () => {
    setDepositForm(false);
    setCurrencyModal("bank");
    setChangeFromModal(true);
  };

  const handleCurrencyChange = () => {
    setDepositForm(false);
    setCurrencyModal("currency");
    setChangeFromModal(true);
  };

  const handlePropChange = (value: any) => {
    const isBankModal = currencyModal === "bank";

    setSelectedBank(isBankModal ? value : null);
    if (isBankModal && changeFromModal) {
      setChangeFromModal(false);
      setDepositForm(true);
    } else if (!isBankModal) {
      selectCurrency("deposit")(value);
      if (changeFromModal) {
        setChangeFromModal(false);
        setDepositForm(true);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDepositAmount(+e.target.value);
  };

  const handleDepositRequest = () => {
    if (isNaN(depositAmount as number)) return;
    const amount = +(depositAmount || 0);
    requestDeposit(
      {
        amount,
        currency: selectedDepositCurrency,
        info_source: selectedBank!,
      },
      (resp: any) => {
        if (resp.error) {
          toast.error(err(String(resp.error.code)));
        } else {
          setDepositForm(false);
          navigate(PATH_LIST.account);
          toast.success("Request sent");
        }
      }
    );
  };

  if (!depositInfo || isLoading) {
    return (
      <div className="deposit">
        <div className="deposit-header">
          <img
            className="go-back-portfolio"
            onClick={handleNavigate}
            src={GoBack}
            alt="Go Back"
          />
          <p>{t("deposit")}</p>
        </div>

        {isLoading ? <Loading /> : <p>{t("noData")}</p>}
      </div>
    );
  }
  return (
    <div className="deposit">
      <div className="deposit-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("deposit")}</p>
      </div>

      <div className="deposit-main">
        <p className="deposit-title">{t("replenishmentDetails")}</p>
        <ReplenishmentDetails
          nameOfDetail={t("fullName")}
          detail={userName as string}
        />

        <Currency
          image={CURRENCY_FLAGS[selectedDepositCurrency]}
          currency={selectedDepositCurrency}
          onClick={() => setCurrencyModal("currency")}
        />
        <Currency
          image={depositInfo.info_source.image}
          onClick={() => setCurrencyModal("bank")}
          bank={depositInfo.info_source.name}
        />

        <ReplenishmentDetails
          nameOfDetail={t("accountNumber")}
          detail={depositInfo.accountNumber}
        />
        <ReplenishmentDetails nameOfDetail={t("contract")} detail={contract} />
        <ReplenishmentDetails
          nameOfDetail={t("recipient")}
          detail={depositInfo.recipient}
        />

        <ReplenishmentDetails
          nameOfDetail={t("purpose")}
          detail={t("deposit-purpose", {
            contract: contract,
          })}
        />

        <div className="btn-wrapper">
          <Button
            children={t("iMadeDeposit")}
            variant="dark"
            onClick={() => setDepositForm(true)}
          />
        </div>
      </div>

      {currencyModal && (
        <CurrencyModal
          onClose={() => setCurrencyModal(null)}
          onCurrencyChange={handlePropChange}
          selectedCurrency={selectedDepositCurrency}
          selectedBank={selectedBank}
          currencies={Array.from(currencies)}
          type={currencyModal}
          banks={
            currencyModal === "bank"
              ? depositAccounts
                  .filter((d) => d.currency.code === selectedDepositCurrency)
                  .map((d) => d.info_source)
              : undefined
          }
        />
      )}

      {depositForm && (
        <Modal
          customModal={"inform-about-deposit-modal"}
          onClick={() => {
            handleDepositRequest();
          }}
          onHide={() => setDepositForm(false)}
          title={t("depositConfirmation")}
          text={t("toSpeedUpProcessing")}
          showSecondBtn={false}
          customOutlet="customOutlet"
          depositFields={{
            bank: depositInfo.info_source.name,
            logo: depositInfo.info_source.image,
            onBankChange: handleBankChange,
            currency: selectedDepositCurrency,
            icon: CURRENCY_FLAGS[selectedDepositCurrency],
            onCurrencyChange: handleCurrencyChange,
          }}
          btnText={t("confirm")}
          onChange={handleInputChange}
          depositAmount={depositAmount}
        />
      )}
    </div>
  );
};
