import React from "react";
import { useStore } from "zustand";
import { accountStore, KYC_Status } from "../entities/account";
import { EntityType } from "../static/types";
import {
  sessionStore,
  useCurrentUser,
} from "entities/session/model/sessionModel";

const useCanAction = () => {
  const user = useCurrentUser();
  const { entity } = useStore(sessionStore);
  const {
    setInactiveAccountModal,
    setSignContractModal,
    setInactivePinCodeModal,
    setInactiveEmailModal,
    setNotVerifiedModal,
    logout,
  } = useStore(sessionStore);
  const { kyc, kyb } = useStore(accountStore);

  const action = (cb: any) => {
    if (!user) {
      // logout
    } else if (
      entity === EntityType.Individual
        ? !kyc || [KYC_Status.None, KYC_Status.Rejected].includes(kyc?.status)
        : !kyb || [KYC_Status.None, KYC_Status.Rejected].includes(kyb?.status)
    ) {
      setNotVerifiedModal(true);
    } else if (
      entity === EntityType.Individual
        ? kyc?.status === KYC_Status.Pending
        : kyb?.status === KYC_Status.Pending
    ) {
      setInactiveAccountModal(true);
    } else if (!user?.isEmailVerified) {
      setInactiveEmailModal(true);
    } else if (!user?.hasPinCode) {
      setInactivePinCodeModal(true);
    } else if (!user?.signContract) {
      setSignContractModal(true);
    } else {
      cb();
    }
  };

  return action;
};

export default useCanAction;
