import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type OpenURLButtonProps = {
  url: string;
  children: string;
  font?: number;
  color?: string;
};

export const OpenURLButton: FC<OpenURLButtonProps> = ({ url, children, font, color }) => {
  const handlePress = useCallback(async () => {
    try {
      window.open(url, '_blank');
    } catch (error) {
      alert('Error opening URL');
    }
  }, [url]);

  return (
    <button onClick={handlePress} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
      <span
        style={{
          textDecoration: 'underline',
          color: color || '#2F80ED',
          fontSize: font || 14,
          fontFamily: 'NotoSans-Regular',
        }}
      >
        {children}
      </span>
    </button>
  );
};

type PropType = {
  term: string;
  isChecked?: boolean;
  onChange?: () => void;
  disabled?: boolean;
  font?: number;
  isSquare?: boolean;
};

const QuestionCheckbox: FC<PropType> = ({
  term,
  isChecked,
  onChange,
  disabled,
  font,
  isSquare,
}) => {
  const { i18n } = useTranslation('onboarding-auth');

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
      <input
        type="checkbox"
        style={{ width: '20px', height: '20px' }}
        checked={isChecked}
        onChange={onChange}
        disabled={disabled}
        className={isSquare ? 'square-checkbox' : 'circle-checkbox'}
      />
      <span
        onClick={onChange}
        style={{
          cursor: 'pointer',
          color: '#2B2F38',
          fontSize: font || 14,
          fontFamily: 'NotoSans-Regular',
          width: '80%',
        }}
      >
        {term}
      </span>
    </div>
  );
};

export default QuestionCheckbox;
