import React, { FC } from "react";

type PropsType = {
  title?: string;
  info: any | any[];
  underInfo?: string;
  background?: string;
  classNameTitle?: string;
};

const formatValue = (info: any) => {
  if (!info) return "";
  const text = String(info);
  if (text.trim() && +text <= 2) {
    return text + "%";
  } else {
    return text;
  }
};

const TariffColumn: FC<PropsType> = ({
  title,
  background,
  classNameTitle,
  info,
  underInfo,
}) => {
  return (
    <>
      <tr className={background}>
        <td className={`title ${classNameTitle}`}>{title}</td>
        {classNameTitle !== "lightTitle" ? (
          <td>
            <span>{formatValue(info)}</span>
            {underInfo && <br />}
            {underInfo && <span>{underInfo}</span>}
          </td>
        ) : (
          <td style={{ padding: 0 }}></td>
        )}
      </tr>
    </>
  );
};

export default TariffColumn;
