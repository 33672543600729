import React, { useEffect, useRef, useState } from "react";
import Button from "shared/ui/button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "shared/ui/form-field";
import DateInput from "../components/date-input";
import { useTranslation } from "react-i18next";
import { useSchema } from "helpers/functions";
import moment from "moment";
import { toast } from "react-toastify";

const DateSchema = (t: any) =>
  Yup.object().shape({
    day: Yup.number()
      .required(t("dayRequired"))
      .min(1, t("dayMustBeleast"))
      .max(31, t("dayMustBeMost")),
    month: Yup.number()
      .required(t("monthRequired"))
      .min(1, t("montMustBeleast"))
      .max(12, t("montMustBeMost")),
    year: Yup.number()
      .required(t("yearRequired"))
      .min(1900, t("yearMustBeleast"))
      .max(new Date().getFullYear() - 18, t("yearMustBeMost")),
  });

type PropsType = {
  initialValues: any;
  onClick?: any;
};

export const BirthdayForm: React.FC<PropsType> = ({
  initialValues,
  onClick,
}) => {
  const { t } = useTranslation("onboarding-auth");
  const { t: y } = useTranslation("yup");

  const schema = useSchema(DateSchema);

  // Create refs for each input field
  const dayInputRef = useRef<any>(null);
  const monthInputRef = useRef<any>(null);
  const yearInputRef = useRef<any>(null);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        const bDate = moment(
          `${values.day}.${values.month}.${values.year}`,
          "DD.MM.YYYY"
        );
        if (moment().diff(bDate, "years") < 18) {
          toast.error(y("yearMustBeMost"));
        } else {
          onClick(
            moment(
              `${values.day}.${values.month}.${values.year}`,
              "DD.MM.YYYY"
            ).format("DD.MM.YYYY")
          );
        }
      }}
    >
      {(formik) => {
        const { errors, touched, isValid, dirty, setFieldValue, values } =
          formik;

        // Handle field change to automatically move to the next input
        const handleFieldChange = (field: string, value: string) => {
          setFieldValue(field, value);

          if (field === "day" && value.length === 2) {
            monthInputRef.current?.focus();
          } else if (field === "month" && value.length === 2) {
            yearInputRef.current?.focus();
          }
        };

        return (
          <Form>
            <div className="dateInputCards">
              <DateInput
                id="day"
                label={t("day")}
                maxLength={2}
                placeholder={t("dd")}
                autoFocus={true}
                isInvalid={!!errors.day && !!touched.day}
                setFieldValue={handleFieldChange}
                touched={!!touched.day}
                value={values.day}
                inputRef={dayInputRef}
              />
              <DateInput
                id="month"
                label={t("month")}
                maxLength={2}
                placeholder={t("mm")}
                isInvalid={!!errors.month && !!touched.month}
                setFieldValue={handleFieldChange}
                touched={!!touched.month}
                value={values.month}
                inputRef={monthInputRef}
              />
              <DateInput
                id="year"
                label={t("year")}
                maxLength={4}
                placeholder={t("yyyy")}
                isInvalid={!!errors.year && !!touched.year}
                setFieldValue={handleFieldChange}
                touched={!!touched.year}
                value={values.year}
                inputRef={yearInputRef}
                customClass="large"
              />
            </div>

            <div className="btn-wrapper">
              <Button
                type="submit"
                disabled={!isValid}
                variant="dark"
                className="registerBirthdayBtn"
              >
                {t("continue")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
