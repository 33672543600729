import React, { FC } from "react";
import "./style.scss";
import ModalButton from "widgets/auth/components/modal-btn";
import Close from "../../../../assets/Close.svg";

type PropsType = {
  title: string;
  text: string;
  btnText: string;
  onClick: () => void;
  onHide?: () => void;
  dontHide?: boolean;
};
export const AdviserModal: React.FC<PropsType> = ({
  title,
  text,
  btnText,
  onClick,
  onHide,
  dontHide,
}) => {
  return (
    <div onClick={dontHide ? undefined : onHide} className="outlet-adviser">
      <div className="modal-adviser">
        <div className="modal-adviser-header">
          <p>{title}</p>
          {onHide && (
            <img onClick={onHide} className="close" src={Close} alt="Close" />
          )}
        </div>
        <span>{text}</span>
        <ModalButton onClick={onClick}>{btnText}</ModalButton>
      </div>
    </div>
  );
};
