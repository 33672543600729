import React from "react";
import "./style.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import JoinUsVariant from "../components/join-us-variant";
import Individual from "../../../assets/Individual.svg";
import Business from "../../../assets/Business.svg";
import { PATH_LIST } from "shared/lib/react-router";
import { RegisterEnum, onboardModel } from "entities/onboard";
import CameraAccessDeniedModal from "../components/camera-access-denied";
import CameraAccessNotFoundModal from "../components/camera-access-not-found";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";

export const ChooseAccTypePage: React.FC = () => {
  // const setRegisterType = sessionModel.sessionStore((state) => state.setRegisterType);
  const navigate = useNavigate();
  const { clear } = useStore(onboardModel.onboardStore);
  const { t } = useTranslation("onboarding-auth");

  return (
    <div className="createAcc">
      <p className="joinUs">{t("joinUs")}</p>
      <span>{t("openAccount")}</span>

      <div className="joinCards">
        <JoinUsVariant
          accountVariant={t("individual")}
          image={Individual}
          info={t("personalAccount")}
          onClick={() => {
            clear();
            onboardModel.onboardStore.setState({
              registerType: RegisterEnum.Individual,
            });
            navigate(PATH_LIST.registration);
          }}
        />
        <JoinUsVariant
          accountVariant={t("business")}
          image={Business}
          info={t("companyAccount")}
          onClick={() => {
            clear();
            onboardModel.onboardStore.setState({
              registerType: RegisterEnum.Business,
            });
            navigate(PATH_LIST.registration);
          }}
        />
      </div>

      <NavLink className="navLinkLoginMobile" to={PATH_LIST.login}>
        <p>
          {t("haveAcc")} <span>{t("logIn")}</span>
        </p>
      </NavLink>
    </div>
  );
};
