import React, { useState } from "react";
import Close from "../../../assets/Close.svg";
import "./style.scss";
import ModalButton from "../components/modal-btn";
import AuthCode from "react-auth-code-input";
import { useTranslation } from "react-i18next";
import FormField from "shared/ui/form-field";

interface BankDetails {
  bank: string;
  logo: string;
  onBankChange: () => void;
}

interface CurrencyDetails {
  currency: string;
  icon: string;
  onCurrencyChange: () => void;
}

interface DepositFields extends BankDetails, CurrencyDetails {}

type PropsType = {
  title: string;
  text: string;
  btnText?: string;
  btnText2?: string;
  showSecondBtn: boolean;
  onClick: any;
  onClickSecondBtn?: () => void;
  onHide: any;
  closeIcon?: boolean;
  customModal?: string;
  customOutlet?: string;
  confirmDeal?: boolean;
  isLoading?: boolean;
  dealError?: boolean;
  depositFields?: DepositFields;
  depositAmount?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Modal: React.FC<PropsType> = ({
  title,
  text,
  btnText,
  btnText2,
  showSecondBtn,
  onClick,
  onClickSecondBtn,
  onHide,
  closeIcon = true,
  customModal,
  customOutlet,
  confirmDeal = false,
  isLoading = false,
  dealError = false,
  depositFields,
  depositAmount,
  onChange,
}) => {
  const [pin, setPin] = useState("");
  const { t: y } = useTranslation("yup");
  const { t } = useTranslation("app");

  const handleOnChange = (newValue: string) => {
    setPin(newValue);
  };

  return (
    <div onClick={onHide} className={`outlet ${customOutlet}`}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={` ${customModal} modal accountAlreadyExistsModal`}
      >
        {closeIcon === true && (
          <div
            className={confirmDeal ? "closeDiv closeConfirmDeal" : "closeDiv"}
          >
            <img onClick={onHide} className="close" src={Close} alt="Close" />
          </div>
        )}
        <p>{title}</p>
        <span>{text}</span>

        {confirmDeal && (
          <div className="authCode-wrapper">
            <AuthCode
              // ref={AuthInputR  ef}
              allowedCharacters="numeric"
              length={4}
              onChange={handleOnChange}
            />
            {dealError && (
              <span className="error-verify">{y("incorrectPIN")}</span>
            )}
          </div>
        )}

        {depositFields && (
          <div className="deposit-fields-form">
            <div className="deposit-modal-field">
              <label htmlFor="depositField">{t("amount")}</label>
              <input
                id="depositField"
                type="number"
                placeholder={t("exactAmount")}
                autoFocus
                onChange={onChange}
                value={depositAmount}
              />
            </div>

            <div
              className="info-field"
              onClick={depositFields.onCurrencyChange}
            >
              <label id="bank">{t("currency")}</label>
              <div>
                <span>{depositFields.currency}</span>
                <img src={depositFields.icon} alt="icon" />
              </div>
            </div>

            <div className="info-field" onClick={depositFields.onBankChange}>
              <label id="bank">{t("bank")}</label>
              <div>
                <span>{depositFields.bank}</span>
                <img src={depositFields.logo} alt="logo" />
              </div>
            </div>
          </div>
        )}

        {btnText && (
          <ModalButton
            disabled={
              isLoading ||
              (confirmDeal && pin.length !== 4) ||
              (depositFields && !depositAmount)
            }
            onClick={(e: any) => {
              e.stopPropagation();
              if (confirmDeal) {
                onClick(pin);
              } else {
                onClick();
              }
            }}
          >
            {btnText}
          </ModalButton>
        )}

        {showSecondBtn && (
          <ModalButton customClass="secondBtn" onClick={onClickSecondBtn}>
            {btnText2}
          </ModalButton>
        )}
      </div>
    </div>
  );
};

export default Modal;
