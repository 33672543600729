import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { RegisterEmailForm } from "widgets/auth/forms";
import { useStore } from "zustand";
import { accountStore } from "entities/account";
import { useTranslation } from "react-i18next";
import { sessionModel } from "entities/session";

export const ChangeEmailPage = () => {
  const { isLoading, requestChangeEmail } = useStore(accountStore);
  const { i18n, t: err } = useTranslation("errors");
  const { t } = useTranslation("app");
  const { user } = useStore(sessionModel.sessionStore);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(PATH_LIST.personal);
  };

  const onSubmit = (email: string) => {
    requestChangeEmail({ email, language: i18n.language }, err, () => {
      navigate(PATH_LIST.verifyChangedEmail);
    });
  };

  return (
    <div className="change-email">
      <div className="change-email-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
      </div>
      <div className="change-email-main">
        <p>{t("yourEmail")}</p>
        <span>{t("weWillSendVerification")}</span>

        <RegisterEmailForm
          customClass="change-email-form"
          isSubmit={isLoading}
          initialValues={{
            email: user?.isEmailVerified ? "" : user?.email || "",
          }}
          onSubmit={onSubmit}
          wrapper={true}
        />
      </div>
    </div>
  );
};
