import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { TabBtn } from "../components/tab-btn";
import { ActionBtn } from "../components/action-btn";
import DividentsDetails from "../components/dividents-details-row";
import ReviewDetails from "../components/review-details-row";
import { useStore } from "zustand";
import { marketStore } from "entities/market";
import { AssetType, OperationType } from "static/types";
import { formatMarketCap, formatPrice } from "helpers/functions";
import { DealsTable, MarketChart } from "widgets/market";
import AssetIcon from "../components/asset-icon";
import HorizontalScroll from "../components/horizontal-scroll";
import { dashboardStore } from "entities/dashboard";
import { ioRTStore } from "services/rt.socket";
import useCanAction from "hooks/useCanAction";
import ExchangeStatusComp from "widgets/market/exchange-status";
import ExchangeSource from "widgets/market/exchange-source";
import ExchangeStatusBtn from "widgets/market/exchange-status-btn";
import useExchangeTimeFrame from "hooks/useExchangeTimeFrame";
import { useBlockScrollbarFromIndividuallyWrapped } from "hooks/useBlockScrollbar";
import { useTranslation } from "react-i18next";
import { useAssetVolatility } from "hooks/useAssetVolatility";
import { GetCountryByCode } from "static/countries";
import SavedToFavNotification from "../components/saved-to-favorites-notification";
import SaveToFavBtn from "../components/save-to-favorites-btn";
import useSaveHandler from "hooks/useSaveToFavorites";
import TV_TechicalAnalysisWidget from "widgets/market/trading-view/techical-analysis";
import TV_NewsWidget from "widgets/market/trading-view/news";
import TV_FinancialWidget from "widgets/market/trading-view/financial";

export const StockDetailsPage = () => {
  const [activeTab, setActiveTab] = useState("Bargaining");
  const { asset_id } = useParams();
  const [stockStatus, setStockStatus] = useState(false);
  const { i18n, t } = useTranslation("app");
  // const [saved, setSaved] = useState(false);
  // const [showNotification, setShowNotification] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const timeRef = useRef<NodeJS.Timeout | null>(null);

  useBlockScrollbarFromIndividuallyWrapped(stockStatus, "stock-details", [
    stockStatus,
  ]);

  const {
    isLoading,
    assetInfo,
    loadAssetInfo,
    loadAssetChart,
    sectors,
    stockIndustries,
    addFavorites,
    removeFavorite,
    favorites,
  } = useStore(marketStore);

  const symbol = assetInfo?.asset?.symbol || "";

  const { connected, subscribe, unsubscribe } = useStore(ioRTStore);
  const exchange = useExchangeTimeFrame(assetInfo?.asset?.exchange);

  const action = useCanAction();

  const getAssetInfo = useCallback(() => {
    try {
      const info = assetInfo?.info;
      return info;
    } catch (error) {
      return null;
    }
  }, [assetInfo]);

  //todo _______________________________________________________

  // const getAssetPrice = useCallback(
  //   (type: "buy" | "sell", symbol) => {
  //     try {
  //       const asset = assetInfo?.asset;
  //       const price = type === "buy" ? asset?.ask : asset?.bid;
  //       return `${price} ${assetInfo!.asset!.currency.symbol}`;
  //     } catch (error) {
  //       return "";
  //     }
  //   },
  //   [assetInfo, exchange]
  // );

  const getAssetPrice = useCallback(
    (type: "buy" | "sell", symbol: boolean = true) => {
      try {
        const asset = assetInfo?.asset;
        const price = type === "buy" ? asset?.ask : asset?.bid;
        const assetSymbol = symbol ? assetInfo?.asset?.currency.symbol : "";
        return `${price} ${assetSymbol}`;
      } catch (error) {
        return "";
      }
    },
    [assetInfo]
  );

  const { changedPart, unchangedPart, volatilityElementRef } =
    useAssetVolatility(+getAssetPrice("buy", false));

  const getAssetMarketCap = useCallback(() => {
    try {
      const cap = assetInfo?.info?.mktCap;
      const curr = assetInfo?.info?.currency || "";
      if (cap) {
        return `${formatMarketCap(cap)} ${curr}`;
      } else {
        return "";
      }
    } catch (error) {
      return "";
    }
  }, [assetInfo]);

  const getAssetSector = useCallback(() => {
    try {
      const sector = assetInfo?.info?.sector;
      if (sector) {
        const findSector = sectors.find((s) => s.alias === sector);
        return findSector ? findSector[`name_en`] : sector; // ${i18n.language}
      } else {
        return "";
      }
    } catch (error) {
      return "";
    }
  }, [assetInfo, sectors]);

  const getAssetIndustry = useCallback(() => {
    try {
      const industry = assetInfo?.info?.industry;
      if (industry) {
        const findSector = stockIndustries.find((s) => s.alias === industry);
        return findSector ? findSector[`name_en`] : industry; // ${i18n.language}
      } else {
        return "";
      }
    } catch (error) {
      return "";
    }
  }, [assetInfo, stockIndustries]);

  const getAssetCountry = useCallback(() => {
    try {
      const country = assetInfo?.info?.country;
      if (country) {
        return GetCountryByCode(country)?.name || country;
      } else {
        return "";
      }
    } catch (error) {
      return "";
    }
  }, [assetInfo]);

  const getAssetDividends = useCallback(() => {
    try {
      const asset = assetInfo?.asset;
      if (!asset) return [];

      const price = (asset?.bid + asset?.ask) / 2;
      const data = assetInfo?.dividends.historical;
      return data
        ? data.slice(0, 50).map((d, i) => {
            const year = d.date.split("-")[0];
            const yearDividends = data.filter(
              (f) => f.date.split("-")[0] === year
            );
            const showYear = i === 0 || year !== data[i - 1].date.split("-")[0];

            return {
              ...d,
              showYear,
              percent: String(+((100 / price) * d.dividend).toFixed(2)) + "%",
              annuallyYield: showYear
                ? String(
                    +(
                      (100 / price) *
                      yearDividends
                        .map((v) => v.dividend)
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2)
                  ) + "%"
                : "",
            };
          })
        : [];
    } catch (error) {
      return [];
    }
  }, [assetInfo]);

  const getAssetDayChange = useCallback(() => {
    try {
      const quote = assetInfo?.asset;

      if (quote?.change && quote.changesPercentage) {
        return {
          isLoss: quote!.change < 0,
          percent: `${quote!.changesPercentage}%`,
          amount: `${quote!.change}$`,
        };
      } else {
        return {
          isLoss: false,
          percent: "",
          amount: "",
        };
      }
    } catch (error) {
      return {
        isLoss: false,
        percent: "",
        amount: "",
      };
    }
  }, [assetInfo]);

  useEffect(() => {
    loadAssetInfo(asset_id);
    loadAssetChart(asset_id);
  }, []);

  const isExchangeActive = !!exchange?.isActive;
  const exchangeCode = exchange?.exchange_code;

  useEffect(() => {
    if (!connected || !exchangeCode || !symbol || !isExchangeActive) {
      return;
    }

    const ticker = `${exchangeCode}~${symbol}`;
    subscribe(ticker);

    return () => {
      unsubscribe(ticker);
    };
  }, [symbol, exchangeCode, isExchangeActive, connected]);

  const navigate = useNavigate();
  const location = useLocation();
  const { prevPathDeal } = useStore(dashboardStore);
  const [toPath, setToPath] = useState<any>(-1);

  useEffect(() => {
    if (prevPathDeal === true) {
      setToPath(PATH_LIST.market);
    } else {
      setToPath(-1);
    }
  }, [location]);

  const handleNavigate = () => {
    navigate(toPath);
  };

  const handleDeal = (type: number, operation: string) => {
    if (operation === "buy") {
      navigate(PATH_LIST.buy(asset_id) + `?type=${type}`);
    } else {
      navigate(PATH_LIST.sell(asset_id) + `?type=${type}`);
    }
  };
  const scroll = () => {
    const stockDetails = document.querySelector(".stock-details");
    if (stockDetails) {
      stockDetails.scrollTop = 0;
    }
  };
  const handleTabClick = (text: string) => {
    setActiveTab(text);
    scroll();
  };

  const { saved, showNotification, handleSave } = useSaveHandler(
    +asset_id!,
    addFavorites,
    removeFavorite
  );

  if (isLoading) {
    return (
      <div className={`stock-details stock-details${activeTab}`}>
        <div className="stock-details-header">
          <img
            className="go-back-portfolio"
            onClick={handleNavigate}
            src={GoBack}
            alt="Go Back"
          />
          <div>
            <p>{symbol}</p>
          </div>
        </div>
        <div className="stock-details-main">
          <p className="assets-loading assets-loading-page">{t("loading")}</p>
        </div>
      </div>
    );
  }

  return (
    <div
      id="stock-details"
      className={`stock-details stock-details${activeTab}`}
    >
      {showNotification && <SavedToFavNotification symbol={symbol} />}

      <div className="stock-details-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <div>
          <p>{symbol}</p>
          <span className="stock-details-header-span">
            {getAssetInfo()?.companyName || assetInfo?.asset?.name || ""}
          </span>
        </div>

        {/* @ts-ignore */}
        <SaveToFavBtn onclick={() => handleSave(asset_id)} saved={saved} />
      </div>
      <HorizontalScroll className="tab-bar">
        <TabBtn
          text={t("trading")}
          active={activeTab === "Bargaining"}
          onClick={() => handleTabClick("Bargaining")}
        />
        {!!getAssetDividends().length && (
          <TabBtn
            text={t("dividends")}
            active={activeTab === "Dividents"}
            onClick={() => handleTabClick("Dividents")}
          />
        )}
        {assetInfo?.tv_ticker && (
          <TabBtn
            text={t("indicators")}
            active={activeTab === "Indicators"}
            onClick={() => handleTabClick("Indicators")}
          />
        )}
        <TabBtn
          text={t("review")}
          active={activeTab === "Review"}
          onClick={() => handleTabClick("Review")}
        />
        {assetInfo?.tv_ticker && (
          <TabBtn
            text={t("news")}
            active={activeTab === "News"}
            onClick={() => handleTabClick("News")}
          />
        )}
      </HorizontalScroll>
      {activeTab === "Bargaining" ? (
        <div className="stock-details-main">
          <div className="stock-info-bargaining">
            <div className="stock-info">
              <AssetIcon
                asset={{
                  type: assetInfo?.asset?.type || AssetType.Stock,
                  symbol: symbol!,
                }}
                className="asset-logo"
                defaultIcon={
                  assetInfo?.asset?.exchange ? "" : getAssetInfo()?.image || ""
                }
              />
              <div style={{ width: "100%" }}>
                <div className="stock-info-header">
                  <div className="stock-price-status">
                    {/* //todo ............  */}
                    {/* {changedPart !== NaN && ( */}

                    <p className="price" style={{ display: "flex" }}>
                      {unchangedPart}

                      <span
                        className="price"
                        id="volatility"
                        ref={volatilityElementRef}
                      >
                        {changedPart}
                      </span>
                      {assetInfo?.asset?.currency.symbol}
                    </p>

                    {/* <p className="price">{getAssetPrice("buy")}</p> */}

                    {exchange && (
                      <ExchangeStatusBtn
                        onClick={() => setStockStatus(true)}
                        status={exchange.status}
                      />
                    )}
                  </div>
                  {exchange && (
                    <ExchangeSource
                      name={exchange.exchange_name}
                      code={exchange.exchange_code}
                      image={exchange.exchange_logo}
                    />
                  )}
                </div>
                {getAssetDayChange().percent && (
                  <>
                    <div className="gain-percent">
                      <span
                        className={getAssetDayChange().isLoss ? "loss" : ""}
                      >
                        {getAssetDayChange().amount}
                      </span>
                      <span
                        className={
                          "gains-percent " +
                          (getAssetDayChange().isLoss ? "loss" : "")
                        }
                      >
                        {getAssetDayChange().percent}
                      </span>
                    </div>
                    <p>{t("today")}</p>
                  </>
                )}
              </div>
            </div>
          </div>
          <MarketChart />
          <DealsTable
            assetId={assetInfo?.asset?.id || undefined}
            realTimePrices={{
              bid: getAssetPrice("sell"),
              ask: getAssetPrice("buy"),
            }}
          />
          {stockStatus && exchange && (
            <ExchangeStatusComp
              tf={exchange}
              onClose={() => setStockStatus(false)}
            />
          )}
        </div>
      ) : activeTab === "Dividents" ? (
        <div className="stock-details-main stock-details-main-dividents">
          {assetInfo?.dividends?.historical?.length ? (
            <React.Fragment>
              <div className="dividents-title">
                <p>{t("registrationClosingDay")}</p>
                <p>{t("dividend")}</p>
              </div>
              {getAssetDividends().map((d, i) => (
                <DividentsDetails
                  key={d.date}
                  yearString={d.date.split("-")[0]}
                  date={`${t("dividend-ex-date")}: ${d.date}`}
                  price={`${d.dividend} ${
                    assetInfo?.asset?.currency?.symbol || ""
                  }`}
                  // year={`${i18n.language !== "am" ? t("dividentsFor") : ""} ${
                  //   // d.date.split("-")[0]
                  //   d.paymentDate
                  // } ${i18n.language === "am" ? t("dividentsFor") : ""}`}
                  year={
                    d.paymentDate
                      ? `${t("dividend-payment-date")}: ${d.paymentDate}`
                      : ""
                  }
                  percent={d.percent}
                  showYear={d.showYear}
                  annuallyYield={d.annuallyYield}
                />
              ))}
            </React.Fragment>
          ) : (
            <div className="not-found-coming-soon">
              <p>{t("noDividends")}</p>
            </div>
          )}
        </div>
      ) : activeTab === "Indicators" ? (
        <div className="stock-details-main ">
          {/* <div className="not-found-coming-soon">
            <p>{t("comingSoon")}</p>
          </div> */}
          <TV_TechicalAnalysisWidget symbol={assetInfo!.tv_ticker!} />
          <TV_FinancialWidget symbol={assetInfo!.tv_ticker!} />
        </div>
      ) : activeTab === "News" ? (
        <div className="stock-details-main ">
          <TV_NewsWidget symbol={assetInfo!.tv_ticker!} />
        </div>
      ) : (
        // <div className="stock-details-main">
        //   <div className="indicators-title">
        //     <p>Доход</p>
        //   </div>
        //   <IndicatorDetails title="Выручка" price="0 $" />
        //   <IndicatorDetails title="Чистая прибыль" price="0 $" />
        //   <IndicatorDetails
        //     title="EBITDA"
        //     additionalInfo="Прибыль до вычета расходов"
        //     price="0 $"
        //   />
        //   <IndicatorDetails
        //     title="CAPEX"
        //     additionalInfo="Капитальные затраты"
        //     price="0 $"
        //   />
        //   <IndicatorDetails
        //     title="FCF"
        //     additionalInfo="Свободный денежный поток"
        //     price="0 $"
        //   />
        //   <IndicatorDetails
        //     title="EPS"
        //     additionalInfo="Прибыль на акцию"
        //     price="0 $"
        //   />
        //   <div className="indicators-title">
        //     <p>Стоимость</p>
        //   </div>
        //   <IndicatorDetails title="Собственный капитал" price="0 $" />
        //   <IndicatorDetails
        //     title="Enterprise value"
        //     additionalInfo="Рыночная стоимость компании на бирже с учетом долга"
        //     price="0 $"
        //   />
        //   <IndicatorDetails
        //     title="P/E"
        //     additionalInfo="Потенциальный срок окупаемости без учета долгов, лет"
        //     price="0 $"
        //   />
        //   <IndicatorDetails
        //     title="P/S"
        //     additionalInfo="Цена/ выручка"
        //     price="0 $"
        //   />
        //   <IndicatorDetails
        //     title="P/S"
        //     additionalInfo="Цена/ балансовая стоимость"
        //     price="0 $"
        //   />
        //   .........
        //   <div className="indicators-title">
        //     <p>Торговля</p>
        //   </div>
        //   <IndicatorDetails
        //     title="Капитализация"
        //     additionalInfo="Рыночная стоимость компании на бирже"
        //     price="67 922,13 млн ₽"
        //     trade={true}
        //   />
        //   <IndicatorDetails
        //     title="Капитализация"
        //     additionalInfo="Цена/ балансовая стоимость"
        //     price="-1,85"
        //     trade={true}
        //   />
        // </div>
        <div className="stock-details-main stock-details-main-review">
          <div className="review-title">
            <p>{t("description")}</p>
          </div>
          {getAssetInfo()?.description && (
            <span className="description-span">
              {getAssetInfo()?.description}
            </span>
          )}
          <div className="description-details">
            <ReviewDetails title={t("sector")} info={getAssetSector()} />
            <ReviewDetails title={t("Industry")} info={getAssetIndustry()} />
            <ReviewDetails title={t("country")} info={getAssetCountry()} />
            <ReviewDetails
              title={t("capitalization")}
              info={getAssetMarketCap()}
            />
          </div>
          <div className="review-title">
            <p>{t("info")}</p>
          </div>
          <div className="info-details">
            {/* <ReviewDetails title="Instrument type" info="Lorem Ipsum" /> */}
            <ReviewDetails
              title={t("ticker")}
              info={getAssetInfo()?.symbol || ""}
            />
            <ReviewDetails title="ISIN" info={getAssetInfo()?.isin || ""} />
            <ReviewDetails
              title={t("currency")}
              info={getAssetInfo()?.currency || ""}
            />
            <ReviewDetails
              title={t("exchange")}
              info={getAssetInfo()?.exchange || ""}
            />
            {/* <ReviewDetails title="Lot size" info="1 lot = 100 piece" />
              <ReviewDetails title="Calculation mode" info="T1" />
              <ReviewDetails title="For qualified investors" info="No" /> */}
          </div>
        </div>
      )}

      <div className="action-btns-wrapper">
        <ActionBtn
          onClick={() =>
            action(() => handleDeal(assetInfo!.asset!.type, OperationType.Sell))
          }
          action={t("sell")}
          price={getAssetPrice("sell")}
          disabled={!isExchangeActive}
        />
        <ActionBtn
          onClick={() =>
            action(() => handleDeal(assetInfo!.asset!.type, OperationType.Buy))
          }
          action={t("buy")}
          price={getAssetPrice("buy")}
          type="dark"
          disabled={!isExchangeActive}
        />
      </div>
    </div>
  );
};
