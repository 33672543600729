import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { PersonalInfoEditCard } from "../components/personal-info-edit-card";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";
import CitizenshipField from "../components/citizenship-field";
import { useState } from "react";
import Armenia from "../../../assets/Armenia.svg";
import Other from "../../../assets/Language.svg";
import VerificationStepsHeader from "pages/onboarding/components/verification-steps-header";
import { accountStore } from "entities/account";
import { toast } from "react-toastify";
import { EntityType } from "static/types";
import { sessionStore } from "entities/session/model/sessionModel";

export const CitizenshipVerificationPage = () => {
  const { t } = useTranslation("app");
  const { t: err } = useTranslation("errors");
  const { startKyc, startKyb, synckyc, synckyb, isLoading } =
    useStore(accountStore);
  const { entity } = useStore(sessionStore);
  const [country, setCountry] = useState("");
  const navigate = useNavigate();

  const onSubmit = async () => {
    const resp =
      entity == EntityType.Individual
        ? await startKyc({ is_armenia_resident: country == "Armenia" })
        : await startKyb({ is_armenia_resident: country == "Armenia" });

    if (resp.error) {
      toast.error(err(String(resp.error.code)));
    } else {
      const kyc = resp.data.data;
      console.log(kyc);
      if (kyc) {
        if (entity == EntityType.Individual) {
          synckyc(kyc);
          navigate(PATH_LIST.registerGender);
        } else {
          synckyb(kyc);
          navigate(PATH_LIST.signBusinessContract);
        }
      }
    }
  };

  return (
    <div className="citizenship-verification">
      <VerificationStepsHeader percent={12.5} step={1} />

      <div className="citizenship-verification-main">
        <p className="citizenship-title">{t("selectCountry")}</p>
        <CitizenshipField
          text={t("armenia")}
          img={Armenia}
          onClick={() => {
            setCountry("Armenia");
            onSubmit();
          }}
        />
        <CitizenshipField
          text={t("other")}
          img={Other}
          onClick={() => {
            setCountry("Other");
            onSubmit();
          }}
        />
      </div>
    </div>
  );
};
