import React, { FC } from "react";
import "./style.scss";
import { UploadFile } from "widgets/auth/components";
import { useTranslation } from "react-i18next";
import { EntityDocumentsEnum } from "entities/onboard/model/onboardModel";

type PropsType = {
  text: string;
  file: string | string[];
  index: number;
  isMultiple: boolean;
  isOptional: boolean;
  type: string;
};

const DocumentRow: FC<PropsType> = ({
  text,
  file,
  index,
  isMultiple,
  isOptional,
  type,
}) => {
  const { t } = useTranslation("onboarding-auth");

  return (
    <div className="documentRow">
      {text === EntityDocumentsEnum.AdditionalDoc ? (
        <p>{t(text)}</p>
      ) : (
        <span>{t(text)}</span>
      )}

      {isMultiple ? (
        <>
          {(file as string[]).map((f, i) => (
            <UploadFile
              key={i}
              id={String(i)}
              isOptional={isOptional}
              file={f}
              type={type}
            />
          ))}
          <UploadFile
            key={file.length}
            id={String(file.length)}
            isOptional={isOptional}
            file={""}
            type={type}
          />
        </>
      ) : (
        <UploadFile id={""} isOptional={isOptional} file={file} type={type} />
      )}
    </div>
  );
};

export default DocumentRow;
